import styled from 'styled-components';

export const BackgroundDiv = styled.div`
  width: 100vw;
  height: calc(100vh - 82px);
  background: linear-gradient(to bottom right, rgba(46, 185, 255, 0.7) 0%, rgba(0, 0, 0, 0) 70%);
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
`;
