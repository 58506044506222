import React from 'react';
import {AcceptButtonContainer, Container, CounterContainer, Description, Image, ImageContainer, Title} from './styles';
import {BackgroundImgContainer, GradientBunner, UsuallyButton} from '../../../../components';
import {assets} from '../../../../assets';

type TProps = {
  onClick: () => void;
  counter: number;
};

const EndGame: React.FC<TProps> = ({onClick, counter}) => {
  return (
    <BackgroundImgContainer>
      <Container>
        <Title>Бій закінчено.</Title>
        <Description>Дякую! Ти клюнула.</Description>
        <CounterContainer>
          <GradientBunner text={`${counter > 0 ? `+${counter}` : counter}`} />
        </CounterContainer>
        <ImageContainer>
          <Image src={assets.fightIsEnd} alt="dino" />
        </ImageContainer>
        <AcceptButtonContainer>
          <UsuallyButton title="Get it!" onClick={onClick} />
        </AcceptButtonContainer>
      </Container>
    </BackgroundImgContainer>
  );
};

export default EndGame;
