export const assets = {
  coin: require('./images/coin.png'),
  dinosaur: require('./images/dinosaur.png'),
  background_mine: require('./backgrounds/mine.png'),
  dinoGroup: require('./images/dinoGroup.png'),
  dinoComingSoon: require('./images/dinoComingSoon.png'),
  dinoPlayMobile: require('./images/dinoPlayMobile.png'),
  stub: require('./images/stub.png'),
  figthMain: require('./backgrounds/fightMain.png'),
  emptyOpponentAvatar: require('./images/emptyOpponenIcon.png'),
  opponentAvatar: require('./images/opponentAvatar.png'),
  userAvatar: require('./images/userAvatar.png'),
  smallEgg: require('./images/smallEgg.png'),
  mediumEgg: require('./images/mediumEgg.png'),
  largeEgg: require('./images/largeEgg.png'),
  extraLargeEgg: require('./images/extraLargeEgg.png'),
  fightIsEnd: require('./images/fightIsEnd.png'),
  bear: require('./images/bear.png'),
  crystal: require('./icons/crystal.svg'),
  hostBearMin: require('./images/host_bear_min.png'),
  opponentBearMin: require('./images/opponent_bear_min.png'),
};
