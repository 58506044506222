import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {
  Container,
  Description,
  FightButtonContainer,
  FightStatusContainer,
  FightStatusTitle,
  InfoContainer,
  Title,
} from './styles';
import {BackgroundImgContainer, Loader, UsuallyButton} from '../../components';
import {useNavigate} from 'react-router-dom';
import {assets} from '../../assets';
import TotalAmount from './components/TotalAmount/TotalAmount';
import {EStatusType} from './types';
import {useAppSelector, useFormattedFightData, useGetFightQuery, useGetUserFightsDataQuery} from '../../hooks';
import {selectUser} from '../../reducers/user';
import {colors} from '../../constants';
import {selectFight} from '../../reducers/fight';

type TProps = {};

const ComingSoon = ({}: TProps) => {
  const navigate = useNavigate();
  const {data: user} = useAppSelector(selectUser);
  const {lastFightId} = useAppSelector(selectFight);

  const {data: activeFightData, refetch} = useGetFightQuery({id: lastFightId ?? ''}, {skip: !lastFightId});

  const {data, isLoading} = useGetUserFightsDataQuery({user_id: user?.id ?? ''}, {skip: !user?.id});

  const formattedFightData = useFormattedFightData(activeFightData ?? data?.fight, user?.id ?? null);

  const onNavigateToSearchOpponents = useCallback(() => {
    // if (!!lastFightId && formattedFightData?.host.score !== null) {
    //   refetch();
    //   navigate('/fight-result');
    // } else {
    //   navigate('/search-opponents');
    // }
  }, [navigate, lastFightId, formattedFightData]);

  return (
    <BackgroundImgContainer>
      <Container>
        <Title>Бийтеся з друзями</Title>
        <Description>
          Запрошуй друзів і ми разом! Гравці з найбільшою мережею друзів зазвичай отримують найбільші бонуси
        </Description>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <FightStatusContainer>
              <FightStatusTitle>Твій статус</FightStatusTitle>
              <InfoContainer>
                <TotalAmount title="Твої бої" amount={data?.fights ?? 0} svgType={EStatusType.FIGHT} />
                <TotalAmount title="Зароблені боями" amount={data?.coins_num ?? 0} svgType={EStatusType.COINS} />
              </InfoContainer>
            </FightStatusContainer>
            <FightButtonContainer>
              <UsuallyButton
                title="В бій!"
                onClick={onNavigateToSearchOpponents}
                background={`linear-gradient(90deg, ${colors.main_gradient_first_color_01CF8F} 0%, ${colors.main_gradient_second_color_0FADAC} 100%)`}
              />
            </FightButtonContainer>
          </>
        )}
      </Container>
    </BackgroundImgContainer>
  );
};

export default ComingSoon;
