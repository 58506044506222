import styled from 'styled-components';
import {colors} from '../../../../constants';

export const Container = styled.div`
  background: linear-gradient(
    90deg,
    ${colors.main_gradient_first_color_01CF8F} 0%,
    ${colors.main_gradient_second_color_0FADAC} 100%
  );
  border: none;
  border-radius: 8px;
  color: white;
  font-size: 1.5em;
  padding: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 86px;
  height: 43px;
`;
