import styled from 'styled-components';
import {colors} from '../../constants';

export const Container = styled.div`
  padding: 24px 16px 28px 16px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  height: 100%;
`;

export const TopContainer = styled.div`
  flex: 1;
`;
export const Title = styled.h1`
  font-size: 20px;
  color: ${colors.white};
  margin-bottom: 8px;
  font-weight: 700;
  text-align: center;
`;

export const Description = styled.p`
  color: ${colors.gray_A4A4A4};
  font-size: 12px;
  text-align: center;
  margin-bottom: 24px;
`;

export const FightStatusContainer = styled.div`
  flex: 1;
`;
export const FightStatusTitle = styled.p`
  font-size: 14px;
  color: ${colors.white};
  font-weight: 700;
  margin-bottom: 8px;
`;

export const FightButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const InfoContainer = styled.div`
  padding: 8px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.11);
`;
