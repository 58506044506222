import styled from 'styled-components';

import {colors} from '../../constants';

export const ErrorBoundaryWrapperStyled = styled.div`
  position: relative;
  width: 100vw;
  height: calc(100vh - 92px);
  background-position: right center;
  background-size: auto 100%;
  background-repeat: no-repeat;
  height: 100vh;
`;

export const ErrorTestStyled = styled.p`
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  font-size: 24px;
  font-weight: 700;
  line-height: 22px;
  text-align: center;
  color: ${colors.white};
`;
