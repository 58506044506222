import styled from 'styled-components';
import {colors} from '../../../../constants';
import {CSSProperties} from 'react';
import {ReactSVG} from 'react-svg';

export const Container = styled.div`
  height: 40px;
  /* background: linear-gradient(90deg, #2c283e 0%, #364147 100%); */
  padding-inline: 12px;
  border-radius: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.p`
  color: ${colors.white};
  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
  align-items: center;
  justify-content: center;
`;

export const AmountContainer = styled.div`
  display: flex;
  height: 24px;
  align-items: center;
  justify-content: center;
`;

export const Amount = styled.span`
  font-weight: 700;
  font-size: 12px;
  color: ${colors.white};
  margin-left: 14px;
`;

export const FightSvgStyle: CSSProperties = {
  width: '24px',
  height: '24px',
  marginRight: '9px',
};

export const CoinSvgStyle: CSSProperties = {
  width: '16px',
  height: '16px',
};

export const FightSvg = styled(ReactSVG)<{$src?: string}>`
  width: 24px;
  height: 24px;
  margin-right: 9px;
`;
