import styled from 'styled-components';
import {colors} from '../../../constants';
import {Styles} from '../../../types';

export const styles: Styles = {
  container: {
    position: 'fixed',
    bottom: 30,
    width: 'calc(100vw - 36px)',
    marginLeft: '18px',
    justifyContent: 'space-between',
    paddingTop: '10.5px',
    paddingBottom: '10.5px',
    backgroundColor: colors.red_6F1812,
    alignItems: 'center',
    borderRadius: '8px',
    marginBottom: '100px',
  },

  title: {
    textDecoration: 'none',
    color: colors.white,
    fontSize: '14px',
    fontWeight: '700',
    marginLeft: '10px',
    textTransform: 'none',
  },
};

export const Button = styled.div`
  position: fixed;
  bottom: 30px;
  width: calc(100vw - 36px);
  margin-left: 18px;
  justify-content: space-between;
  padding-top: 10.5px;
  padding-bottom: 10.5px;
  background: linear-gradient(
    to right,
    ${colors.main_gradient_first_color_01CF8F},
    ${colors.main_gradient_second_color_0FADAC} 66%,
    ${colors.main_gradient_third_color_1F91CA}
  );
  align-items: center;
  border-radius: 8px;
  margin-bottom: 100px;
  display: flex;
  padding-left: 4px;
`;

export const StyledImageLeft = styled.img`
  height: 160%;
  left: 10px;
  bottom: 0px;
  position: absolute;
  transform: translateY(15%);
`;

export const StyledImageRight = styled.img`
  height: 160%;
  right: 10px;
  bottom: 0px;
  position: absolute;
  transform: translateY(15%);
`;
