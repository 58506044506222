import {colors} from '../../constants';
import {Styles} from '../../types';

export const styles: Styles = {
  container: {
    flexDirection: 'column',
    alignItems: 'center',
    paddingLeft: '16px',
    paddingRight: '16px',
    paddingTop: '24px',
    paddingBottom: '24px',
    background: 'linear-gradient(to bottom right, rgba(46, 185, 255, 0.7) 0%, rgba(0, 0, 0, 0) 70%)',
    height: '100%',
  },
};
