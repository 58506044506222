import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {AvatarContainer, BackgroundImgContainer, UsuallyButton} from '../../components';
import {assets} from '../../assets';
import {AvatarsBlockContainer, Container, Description, FightButtonContainer, Title} from './styles';
import {colors} from '../../constants';
import {EStatusFightAvatar} from '../../components/layout/AvatarContainer/types';
import {
  useAppDispatch,
  useAppSelector,
  useFormattedFightData,
  useGetUserFightsDataQuery,
  useToggleUserFightStatusMutation,
} from '../../hooks';
import {selectUser} from '../../reducers/user';
import {useNavigate} from 'react-router-dom';
import {EFightSearchingStatus} from './types';
import {DescriptionBlock} from './components';
import {setLastFightAction} from '../../reducers/fight';

type TProps = {};

const SearchingOpponents: React.FC<TProps> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [pollingInterval, setPollingInterval] = useState(0);

  const {data: user} = useAppSelector(selectUser);
  const {
    data: fightData,
    refetch,
    isLoading: isLoadingFightData,
  } = useGetUserFightsDataQuery({user_id: user?.id ?? ''}, {skip: !user?.id, pollingInterval: pollingInterval});

  const [toggleUserFightStatus, {isLoading, error, data: fightStatusData}] = useToggleUserFightStatusMutation();

  const formattedFightData = useFormattedFightData(fightData?.fight, user?.id ?? null);

  const fightStatus = useMemo(() => {
    if (
      (fightStatusData?.is_search_fight && fightStatusData.fight) ||
      (fightData?.is_search_fight && fightData.fight)
    ) {
      return EFightSearchingStatus.TRUE;
    }
    if ((!fightStatusData && !fightData?.is_search_fight) || (fightStatusData && !fightStatusData?.is_search_fight)) {
      return EFightSearchingStatus.FALSE;
    }
    return EFightSearchingStatus.SEARCHIND;
  }, [fightData, fightStatusData]);

  const onPressToggleUserFightStatus = useCallback(
    (fightStatus: EFightSearchingStatus, userId: string) => async () => {
      if (fightStatus === EFightSearchingStatus.TRUE) {
        navigate('/game');
      } else if (fightStatus === EFightSearchingStatus.FALSE) {
        if (user?.id) {
          await toggleUserFightStatus({user_id: userId});
        }
      } else {
        await toggleUserFightStatus({user_id: userId});
      }
    },
    [],
  );

  useEffect(() => {
    if (fightStatus === EFightSearchingStatus.SEARCHIND) {
      refetch();
      setPollingInterval(60000);
    } else {
      if (fightStatus === EFightSearchingStatus.TRUE) {
        if (formattedFightData) {
          dispatch(setLastFightAction(formattedFightData?.id));
        }
      }
      setPollingInterval(0);
    }
  }, [fightStatus, formattedFightData]);

  return (
    <BackgroundImgContainer>
      <Container>
        <Title>Бийтеся з друзями</Title>
        <Description>
          Запрошуй друзів і ми разом! Гравці з найбільшою мережею друзів зазвичай отримують найбільші бонуси
        </Description>
        <AvatarsBlockContainer>
          <AvatarContainer
            $size={`calc((100vw - 92px - 27px) / 2)`}
            status={
              fightStatus === EFightSearchingStatus.SEARCHIND
                ? EStatusFightAvatar.SEARCHING
                : fightStatus === EFightSearchingStatus.FALSE
                ? EStatusFightAvatar.NOT_AVAILABLE
                : EStatusFightAvatar.READY
            }
            url={fightStatus === EFightSearchingStatus.TRUE ? assets.opponentAvatar : assets.emptyOpponentAvatar}
            title="(Твій суперник)"
            subTitle={
              fightStatus === EFightSearchingStatus.TRUE
                ? fightData?.fight
                  ? fightData?.fight?.fight_host.first_name !== user?.first_name
                    ? `${fightData?.fight?.fight_host.first_name} ${fightData?.fight?.fight_host.last_name}`
                    : `${fightData?.fight?.opponent.first_name} ${fightData?.fight?.opponent.last_name}`
                  : fightStatusData && fightStatusData?.fight
                  ? `${fightStatusData.fight.fight_host.first_name} ${fightStatusData.fight.fight_host.last_name}`
                  : ''
                : ''
            }
          />
          <AvatarContainer
            $size={`calc((100vw - 92px - 27px) / 2)`}
            url={assets.userAvatar}
            status={EStatusFightAvatar.READY}
            title="(Ви)"
            subTitle={user ? `${user?.first_name} ${user?.last_name}` : ''}
          />
        </AvatarsBlockContainer>
        <FightButtonContainer>
          {fightStatus !== EFightSearchingStatus.FALSE ? <DescriptionBlock status={fightStatus} /> : null}
          <UsuallyButton
            title={
              fightStatus === EFightSearchingStatus.TRUE
                ? 'Битва!'
                : fightStatus === EFightSearchingStatus.SEARCHIND
                ? 'Зупинити пошук'
                : 'Почати пошук'
            }
            disable={isLoading}
            $color={fightStatus === EFightSearchingStatus.SEARCHIND ? colors.red_B30D02 : colors.white}
            onClick={onPressToggleUserFightStatus(fightStatus, user?.id ?? '')}
            background={
              fightStatus === EFightSearchingStatus.TRUE
                ? colors.red_A92E2A
                : fightStatus === EFightSearchingStatus.SEARCHIND
                ? colors.white
                : `linear-gradient(90deg, ${colors.main_gradient_first_color_01CF8F} 0%, ${colors.main_gradient_second_color_0FADAC} 100%)`
            }
          />
        </FightButtonContainer>
      </Container>
    </BackgroundImgContainer>
  );
};

export default SearchingOpponents;
