import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {BackgroundImgContainer, GradientBunner, UsuallyButton, UsuallyModal} from '../../components';
import {assets} from '../../assets';
import {ButtonContainer, Container, ContentContainer, ItemsContainer, TimerContainer, Title} from './styles';
import Timer from '../../components/dataview/Timer/Timer';
import {
  useAppDispatch,
  useAppSelector,
  useFormattedFightData,
  useGetFightQuery,
  useLazyUpdateUserQuery,
} from '../../hooks';
import {selectUser, updateUserAction} from '../../reducers/user';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import UserFightInfoItem from './components/UserFightInfoItem/UserFightInfoItem';
import {useNavigate} from 'react-router-dom';
import {EFightStatus} from '../../types/entities/fight';
import {ResultContent} from './components';
import {TUser} from '../../types/entities';
import {resetFightAction, selectFight} from '../../reducers/fight';

dayjs.extend(utc);
dayjs.extend(localizedFormat);

type TProps = {};

const getTimeRemainingInSeconds = (utcTime: string): number => {
  const targetDate = dayjs.utc(utcTime).local();
  const now = dayjs();
  const timeDifferenceInMilliseconds = targetDate.diff(now);
  const timeDifferenceInSeconds = Math.floor(timeDifferenceInMilliseconds / 1000);
  return timeDifferenceInSeconds > 0 ? timeDifferenceInSeconds : 0;
};

const FightResult: React.FC<TProps> = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [modalVisible, setModalVisible] = useState(false);

  const {data: user} = useAppSelector(selectUser);
  const {lastFightId} = useAppSelector(selectFight);

  const [updateUserQuery] = useLazyUpdateUserQuery();
  const {data: activeFightData, refetch} = useGetFightQuery({id: lastFightId ?? ''}, {skip: !lastFightId});

  const formattedFightData = useFormattedFightData(activeFightData, user?.id ?? null);

  const onPressHandler = useCallback(() => {
    if (formattedFightData?.status === EFightStatus.ACTIVE) {
      navigate('/');
    } else {
      setModalVisible(true);
    }
  }, [navigate, formattedFightData]);

  const isWin = useMemo(
    () =>
      formattedFightData &&
      formattedFightData.status === EFightStatus.FINISH &&
      (formattedFightData.host.score ?? 0) > (formattedFightData.opponent.score ?? 0),
    [formattedFightData],
  );

  const onPressGetFightResult = useCallback(
    (userData: TUser | null, onUpdateValue: number) => async () => {
      if (userData) {
        const updateCoinValue = Math.floor(userData.coins_num + onUpdateValue);

        const userDataFormat: TUser = Object.assign({}, user, {
          coins_num: updateCoinValue < 0 ? 0 : updateCoinValue,
        });

        const {data} = await updateUserQuery(JSON.stringify(userDataFormat));
        dispatch(updateUserAction(data));
        dispatch(resetFightAction());
      }
    },
    [],
  );

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (!lastFightId) {
      setModalVisible(false);
      navigate('/fights');
    }
  }, [lastFightId, navigate]);

  return (
    <BackgroundImgContainer>
      <Container>
        <Title>Бийтеся з друзями</Title>
        {formattedFightData && activeFightData ? (
          <ContentContainer>
            <>
              <TimerContainer>
                {formattedFightData.host.score !== null && formattedFightData.opponent.score !== null ? (
                  <GradientBunner text="Time out" />
                ) : (
                  <Timer
                    isStart={true}
                    onTimeEnd={refetch}
                    initialTime={getTimeRemainingInSeconds(activeFightData?.end_at)}
                    format={'hh:mm'}
                  />
                )}
              </TimerContainer>
              <ItemsContainer>
                <UserFightInfoItem
                  url={assets.userAvatar}
                  fio={`${formattedFightData.host.first_name} ${formattedFightData.host.last_name}`}
                  fightStatus={activeFightData.status}
                  points={formattedFightData.host.score}
                />
                <UserFightInfoItem
                  url={assets.opponentAvatar}
                  fio={`${formattedFightData.opponent.first_name} ${formattedFightData.opponent.last_name}`}
                  fightStatus={activeFightData.status}
                  points={formattedFightData.opponent.score}
                />
              </ItemsContainer>
            </>
            <ButtonContainer>
              <UsuallyButton title="Get it" onClick={onPressHandler} />
            </ButtonContainer>
          </ContentContainer>
        ) : (
          <></>
        )}
        <UsuallyModal isVisible={modalVisible} handleClose={() => setModalVisible(false)}>
          <ResultContent
            onPress={onPressGetFightResult(
              user,
              isWin ? formattedFightData?.bet ?? 0 : -(formattedFightData?.bet ?? 0),
            )}
            title={isWin ? 'Вітаємо з перемогою!' : 'На жаль, ви програли(('}
            price={`${isWin ? '+' : '-'}${formattedFightData?.bet ?? 0}`}
          />
        </UsuallyModal>
      </Container>
    </BackgroundImgContainer>
  );
};

export default FightResult;
