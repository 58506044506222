import React, {useCallback, useEffect, useMemo} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import debounce from 'lodash/debounce';
import WebApp from '@twa-dev/sdk';
import {BackgroundImgContainer, BriefCoinInfo, CoinClicker, CoinTotalTracker, ProfileHeader} from '../../components';
import {
  useAddIncomeMutation,
  useAppDispatch,
  useAppSelector,
  useLazyGetOrCreateUserQuery,
  useCheckFightStatus,
  useGetFightQuery,
  useLazyGetUserFightsDataQuery,
} from '../../hooks';
import {incrementCoinAction, selectUser, setInitialUserData, upgradeUserLevelAction} from '../../reducers/user';
import FightWithFriendsButton from '../../components/navigation/FightWithFriendsButton/FightWithFriendsButton';
import {assets} from '../../assets';
import {TUser} from '../../types/entities';
import {resetFightAction, selectFight} from '../../reducers/fight';
import {EFightSearchingStatus} from '../SearchingOpponents/types';

const useQuery = () => new URLSearchParams(useLocation().search);

const Home = () => {
  const {data: user, backgroundMining} = useAppSelector(selectUser);
  const {lastFightId} = useAppSelector(selectFight);

  const [getUserFightProfile, {data: userFightProfile}] = useLazyGetUserFightsDataQuery();
  const {data: activeFightData, isError} = useGetFightQuery({id: lastFightId ?? ''}, {skip: !lastFightId});

  const fightStatus = useCheckFightStatus(isError ? null : lastFightId, user?.id ?? null, userFightProfile);

  useEffect(() => {
    if (user?.id) {
      getUserFightProfile({user_id: user.id});
    }
  }, [user?.id]);

  useEffect(() => {
    if (isError) {
      dispatch(resetFightAction());
    }
  }, [isError]);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [addIncome] = useAddIncomeMutation();
  const [getOrCreateUserQuery] = useLazyGetOrCreateUserQuery();

  const query = useQuery();

  const initialData = WebApp.initData;
  const referral_code = query.get('referral_code');
  const chat_id = query.get('chat_id');

  if (!user) {
    return <></>;
  }

  const addCoins = useCallback(async (user: TUser) => {
    try {
      const response = await getOrCreateUserQuery(
        `${initialData}&referral_code=${referral_code}&chat_id=${chat_id}`,
      ).unwrap();

      // const response = await getOrCreateUserQuery(
      //   `query_id=AAG_b3FTAAAAAL9vcVObiG_D&user=%7B%22id%22%3A1399943103%2C%22first_name%22%3A%22Victoriya%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22SViktoriyaS%22%2C%22language_code%22%3A%22uk%22%2C%22is_premium%22%3Atrue%2C%22allows_write_to_pm%22%3Atrue%7D&auth_date=1722239699&hash=443041127359daa1a65135580575a9e37273b6f94ad152c00c328e32743be153&referral_code=EGSSwNQ7agG8MCKjBdUZMgveWAs8antq&chat_id=672835105`,
      // ).unwrap(); //Vika

      dispatch(setInitialUserData(response));
      if (response && user.coins_num !== response.coins_num) {
        const coins_num = user.coins_num - response.coins_num;
        await addIncome({coins_num: parseInt(coins_num.toFixed(0), 10), id: response.id});
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  const handleDebounce = useMemo(() => debounce(addCoins, 500), [addCoins]);

  const handleIncrement = () => {
    dispatch(incrementCoinAction());
    handleDebounce(user);
  };

  const handlePressInActive = useCallback(() => {
    if (fightStatus === EFightSearchingStatus.FALSE) {
      navigate('/fights');
    } else if (fightStatus === EFightSearchingStatus.FINISHED) {
      navigate('/fight-result');
    } else {
      navigate('/search-opponents');
    }
  }, [fightStatus, lastFightId]);

  return (
    <BackgroundImgContainer>
      <>
        <ProfileHeader userName={user.first_name + ' ' + user.last_name} />
        <CoinTotalTracker coins={user.coins_num} />
        <CoinClicker onClick={handleIncrement} user={user} />
        <BriefCoinInfo userLvl={user.level_num} backgroundMining={backgroundMining} />
        <FightWithFriendsButton title="Бийтеся з друзями" onClick={handlePressInActive} />
      </>
    </BackgroundImgContainer>
  );
};

export default Home;
